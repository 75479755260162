import React from "react"

import Layout from "../components/layout"
import Img from 'gatsby-image'
import SEO from "../components/seo"

const IndexPage = ({data}) => {

  const imgSources = [
    {
      ...data.profileSmall.childImageSharp.fixed,
      media: `(max-width: 749px)`
    },
    {
      ...data.profileLarge.childImageSharp.fixed,
      media: `(min-width: 750px)`,
    },
  ]

  return(
    <Layout>
      <SEO title="Home" />
      <h1>Hi There,</h1>
      <div style={{textAlign:`center`, marginBottom: `1.45rem` }}>
        <Img style={{borderRadius:`450px`}} fixed={imgSources} alt="Picture of Me!"/>
      </div>
      <p>My name is Paul. I'm a Biomedical Engineer turned Software Developer.</p>
      <p>I received my B.S. from the University of Virginia, and my M.S. from Washington University in St. Louis.</p>
      <p>I'm currently a Software Engineer at IXL Learning, and enjoy tinkering on electronics and personal projects.</p>

    </Layout>
  )
}

export const projQuery  = graphql`
    query profileQuery {
        profileLarge: file(relativePath: { eq: "paul-picture.png" }) {
            childImageSharp {
                fixed(width:450, quality:100) {
                    ...GatsbyImageSharpFixed
                }
            }
        }
        profileSmall: file(relativePath: { eq: "paul-picture.png" }) {
          childImageSharp {
              fixed(width:300, quality:100) {
                  ...GatsbyImageSharpFixed
              }
          }
      }

    }
    `

export default IndexPage
